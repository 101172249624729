import React, { ReactNode } from 'react';

import s from './Card.scss';

interface CardProps {
  img: string;
  green?: boolean;
  yellow?: boolean;
  label?: string;
}

export const Card = ({ img, green, yellow, label }: CardProps) => (
  <div className={s(s.cards__card, green ? "green" : "", yellow ? "yellow" : "")}>
    <h6 className={s.cards__card__title}>{label}</h6>
    <img className={s.cards__img} src={img} />
  </div>
);
