import React from "react";
import { Helmet } from "react-helmet";

import BaseLayout from "components/layout/BaseLayout";
import { Hero } from "components/hero/Hero";
import { SectionBlock } from "components/section-box/SectionBlock";
import { SectionBlockInner } from "components/section-box/SectionBlockInner";
import { CardsWrapper } from "components/cards/CardsWrapper";
import { Cards } from "components/cards/Cards";
import { Card } from "components/cards/Card";

import HeroImgPc from "../assets/images/hero-pc.svg"
import HeroImgMobile from "../assets/images/hero-mobile.svg"
import Concept1PC from "../assets/images/concept1-pc.svg"
import Concept2PC from "../assets/images/concept2-pc.svg"
import Concept3PC from "../assets/images/concept3-pc.svg"
import Concept4PC from "../assets/images/concept4-pc.svg"
import Concept5PC from "../assets/images/concept5-pc.svg"
import Concept1SP from "../assets/images/concept1-sp.svg"
import Concept2SP from "../assets/images/concept2-sp.svg"
import Concept3SP from "../assets/images/concept3-sp.svg"
import Concept4SP from "../assets/images/concept4-sp.svg"
import Concept5SP from "../assets/images/concept5-sp.svg"


import Img1 from "../assets/images/uidesign.png"
import Img2 from "../assets/images/uxdesign.png"
import Img3 from "../assets/images/centertable.png"
import Img4 from "../assets/images/graphicdesign.png"
import Img5 from "../assets/images/scrapbook.png"
import Img6 from "../assets/images/groupdesign.png"
import Img7 from "../assets/images/questions.png"
import Img8 from "../assets/images/music.png"

// tslint:disable no-default-export
export default () => (
  <BaseLayout>
    <Helmet title="Yard" />
    <Hero
      titleen={<div>Yard <br />Design <br />Community</div>}
      titlejp="一息つけるデザインコミュニティ"
      imgPC={HeroImgPc}
      imgMobile={HeroImgMobile}
    />
    <SectionBlock
      green
      buttonyellow
      en="about"
      jp="デザインコミュニティって何？"
      description="YardはDiscordを使用した誰でも気軽に参加できるオンライン型のデザインコミュニティです。
      デザイナーの方はもちろん、デザインに興味のある方や学生の方など誰でも参加できます。
      さまざまな思いを持ったみなさんの心の拠り所の一つとなる場所を作りたいと思い、
      Yard デザインコミュニティを立ち上げました。"
      buttontext="Yardを覗いてみる"
      buttonhref="https://discord.com/invite/YGvhbEbVWf"
    />
    <SectionBlock
      en="what we value"
      jp="大切にしよう"
      tabItem1="知る"
      tabItem2="発見する"
      tabItem3="共有する"
      tabItem4="話す"
      tabItem5="出会う"
    >
      <SectionBlockInner
        label="知る"
        title="デザインの新しい情報を知る"
        paragraph="Yardではデザインに関する情報を幅広く発信しています。国内外のデザイントレンドから気になる情報を見つけましょう。デザインに対する知識が増え、あなたの世界がこれまで以上に大きく広がるはずです。"
        imgPC={Concept1PC}
        imgMobile={Concept1SP}
      />
      <SectionBlockInner
        reverse
        label="発見する"
        title="アイデアや気づきを発見できる"
        paragraph="Yardにはデザインに興味を持つ学生から実際に働くデザイナーまで様々な背景を持つ人たちが集まっています。一人では気づかなかった角度からデザインのヒントを見つけましょう。発見したものがあなたを大きく成長させてくれるはずです。"
        imgPC={Concept2PC}
        imgMobile={Concept2SP}
      />
      <SectionBlockInner
        label="共有する"
        title="自分のデザインや悩みを共有する"
        paragraph="あなたのつくったデザインや好きなものをYardのみんなに共有しましょう。楽しみがきっと膨らみます。あなたの悩みをYardのみんなに相談すれば、同じ悩みを抱えた人や助けてくれる人がきっといます。みんなでシェアすれば、きっと心が豊かになるはずです。"
        imgPC={Concept3PC}
        imgMobile={Concept3SP}
      />
      <SectionBlockInner
        reverse

        label="話す"
        title="デザインのことを気軽に話す"
        paragraph="あなたの考えや悩みを周りの人に話してみよう。
        Yardにはあなたを受け入れるたくさんの仲間が集まっています。周りに話すことで気持ちに整理がついたり、新しい目標を見つけられるかもしれません。"
        imgPC={Concept4PC}
        imgMobile={Concept4SP}
      />
      <SectionBlockInner
        label="出会う"
        title="デザインを通して人に出会う"
        paragraph="Yardには様々な背景を持つデザインの仲間が集まっています。普段なかなか話すことができない人と出会い、同じ想いを持つ仲間と巡り合うことができるでしょう。Yardのみんながあなたを待っています。"
        imgPC={Concept5PC}
        imgMobile={Concept5SP}
      />
    </SectionBlock>

    <SectionBlock
      red
      en="place"
      jp="チャットの様子"
      description="Discordを使用して、誰もが気軽に話せるチャットスペースをご用意しています。
      好きなデザインを共有したり、困っていることを相談したり、Yardを最大限に活用してください。"
    >
      <CardsWrapper>
        <Cards>
          <Card label="UIデザイン" img={Img1} />
          <Card label="UXデザイン" img={Img2} green />
          <Card label="雑談" img={Img3} yellow />
          <Card label="グラフィックデザイン" img={Img4} />
          <Card label="グラフィックデザイン" img={Img5} green />
          <Card label="組織デザイン" img={Img6} yellow />
          <Card label="質問・相談" img={Img7} />
          <Card label="音楽" img={Img8} green />
        </Cards>
        <Cards>
          <Card label="UIデザイン" img={Img1} />
          <Card label="UXデザイン" img={Img2} green />
          <Card label="雑談" img={Img3} yellow />
          <Card label="グラフィックデザイン" img={Img4} />
          <Card label="スクラップブック" img={Img5} green />
          <Card label="組織デザイン" img={Img6} yellow />
          <Card label="質問・相談" img={Img7} />
          <Card label="音楽" img={Img8} green />
        </Cards>
      </CardsWrapper>
    </SectionBlock>
    <SectionBlock
      yellow
      buttonred
      en="join yard"
      buttontext="Yardに参加する"
      buttonhref="https://discord.com/invite/YGvhbEbVWf"
    />

  </BaseLayout >
);
