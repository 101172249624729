import React, { useRef, useState, useCallback, ReactNode } from 'react'
import { useSpring, animated } from 'react-spring'

import s from "./Animations.scss";

interface FadeUpProps {
  pos: number;
  start: number;
  end: number;
  offset: ReactNode;
  children: ReactNode;
}

export const FadeUp = ({ children, pos, start, end, offset }: FadeUpProps) => {

  return (
    <animated.div className={s.fadeUp}>
      {children}
    </animated.div>
  )
}
