import React from "react";
import Div100vh from "react-div-100vh"

import s from "./Hero.scss";

interface HeroProps {
  imgPC: string;
  imgMobile: string;
  titleen: string;
  titlejp: string;
}

export const Hero = ({ titleen, titlejp, imgPC, imgMobile }: HeroProps) => (
  <Div100vh className={s.hero}>
    <img className={s.hero__img} src={imgPC} srcSet={`${imgMobile} 768w, ${imgMobile} 1536w, ${imgPC} 1920w`} />
    <div className={s.hero__col}>
      <h1 className={s.hero__col__titleen}>
        {titleen}
      </h1>
      <h1 className={s.hero__col__titlejp}>
        {titlejp}
      </h1>
    </div>
    <div className={s.hero__invitescroll}>
      <span className={s.hero__invitescroll__text}>Scroll</span>
      <span className={s.hero__invitescroll__arrow} />
    </div>
  </Div100vh>
);
