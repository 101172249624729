import React, { ReactNode } from 'react';

import s from './Card.scss';

interface CardsWrapperProps {
  children: ReactNode;
}

export const CardsWrapper = ({ children }: CardsWrapperProps) => (
  <div className={s.cardsWrapper}>
    {children}
  </div>
);
