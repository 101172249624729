import React, { useRef, useState, useCallback, ReactNode } from 'react'
import { FadeUp } from 'components/animations/FadeUp';
import { useSpring, animated } from 'react-spring'
import s from './SectionBlock.scss';

interface SectionBlockInnerProps {
  title: string;
  paragraph: string;
  imgPC: string;
  imgMobile: string;
  label: string;
  reverse?: ReactNode;
}

export const SectionBlockInner = ({ title, paragraph, imgMobile, imgPC, label, reverse }: SectionBlockInnerProps) => {
  const [{ scroll }, set] = useSpring(() => ({ scroll: 0 }))
  const onScroll = useCallback(e => void set({ scroll: e.target.scrollTop / (window.innerHeight / 2) }), [])
  return (
    <div className={s(s.inner, reverse ? "reverse" : "")} onScroll={onScroll}>
      <div className={s(s.inner__leftCol, reverse ? "reverse" : "")}>
        <FadeUp offset={scroll} pos={0} start={0} end={0.5}>
          <span className={s.inner__leftCol__label}>{label}</span>
        </FadeUp>
        <h2 className={s.inner__leftCol__title}>{title}</h2>
        <p className={s.inner__leftCol__paragraph}>{paragraph}</p>
      </div>
      <div className={s(s.inner__rightCol, reverse ? "reverse" : "")}>
        <img className={s.inner__rightCol__img} srcSet={`${imgMobile} 768w, ${imgMobile} 1536w, ${imgPC} 1920w`} />
      </div>
    </div >
  )
}


