import React, { ReactNode } from 'react';

import s from './Card.scss';

interface CardsProps {
  children: ReactNode;
}

export const Cards = ({ children }: CardsProps) => (
  <div className={s.cards}>
    {children}
  </div>
);
